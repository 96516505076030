<template>
    <button @click="show = true" class="btn ml-2 btn-success btn-sm">
        <i class="fa fa-upload"></i> Tải dữ liệu
        <b-modal v-model="show" title="Tải danh sách học viên" @close="close" no-close-on-esc no-close-on-backdrop
                 no-enforce-focus>
            <!-- <div class="form-group">
                <label><b>Tải xuống tập tin mẫu</b></label>
                <div>
                    <button @click="downloadTemplate" class="btn btn-sm btn-secondary mr-1 mb-2">
                        <i class="fa fa-file-excel"></i> {{templateName}}
                    </button>
                </div>
            </div> -->
            <div class="form-group">
                <label><b>Chọn tập tin</b></label>
                <b-form-file v-model="file" :disabled="reading||totalRows>0"
                             placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             drop-placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             browse-text="Chọn tập tin" 
                             accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
            </div>
            <b-form-checkbox v-model="updated" value="1" unchecked-value="0">
                Cho phép cập nhật thông tin khi trùng mã học viên
            </b-form-checkbox>
            <div class='mb-3' ></div>
            <div v-if="reading" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang đọc file. Quá trình này có thể sẽ tốn nhiều thời gian nếu file tải lên có dung lượng lớn. Xin đừng tắt cửa sổ trình duyệt cho đến khi quá trình tải lên hoàn tất!</span>
            </div>
            <div v-if="importing" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang tải dữ liệu lên... Xin hãy kiên nhẫn, việc tải lên sẽ mất một chút thời gian nếu file của bạn quá lớn</span>
            </div>
            <div class="mb-3" v-if="totalRows">
                <b-progress :max="totalRows">
                    <b-progress-bar :value="successCount+errorCount"
                                    :label="`${(((successCount + errorCount) / totalRows) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
                <div class="text-center mt-2">Xử lý {{ successCount + errorCount }}/{{ totalRows }} dòng | <span :class="[errorCount && errorCount > 0? 'text-danger':'']" >Vấn đề: {{ errorCount }} </span>
                    <a v-if='errorCount > 0' @click="downloadErrors" class="btn-link text-primary" href="javascript:;">[Chi tiết]</a>
                </div>
            </div>
            <template v-slot:modal-footer>
                <button class="btn btn-secondary" @click="cancel" v-if="!reading&&importing">Dừng tải</button>
                <button class="btn btn-primary" @click="reset" v-if="!reading&&!importing&&totalRows>0">Làm lại</button>
                <button v-if="totalRows===0" class="btn btn-primary" @click="process" :disabled="reading||importing">
                    <b-spinner small v-if="reading||importing"></b-spinner>
                    Tải lên
                </button>
            </template>
        </b-modal>
    </button>
</template>

<script>
    import Import from "@/mixins/Import";

    export default {
        props: ['params', 'edu'],
        mixins: [Import],
        data() {
            return {
                service: '/edu-student',
                fileName: `dao_tao_chudekhac_hs`,
                templateName: 'dao_tao_chudekhac_nguoi_ccdv.xlsx',
                reloadBus: "reloadEduStudent",
                headers: [
                    {header: 'code', key: 'code', label: 'Mã số người cung cấp dịch vụ*', width: 30, desc: "Nhập định dạng chữ (CCCD/CMT)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'year', key: 'year', label: 'Năm sinh*', width: 10, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 10, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'types', key: 'types', label: 'Loại hình người CCDV*', width: 30, desc: "Nhập 01 giá trị từ 1 đến 4 (Trong đó: " + this.$params.eduPatient.providerTypeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    // {header: 'site_name', key: 'site_name', label: 'Đơn vị công tác', width: 25, decs: "Text"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ],
                indexRow: 4,
            }
        },
        methods: {
            async save(item) {
                if (this.updated == 1) {
                    await this.$service.post(`${this.service}/import?t=u`, item);
                } else {
                    await this.$service.post(`${this.service}/import?t=c`, item);
                }
            },
            async getItem(headerRow, row) {
                let vm = this;
                let item = {
                    edu_id: this.edu.id
                };
                for (let j = 1; j <= headerRow.cellCount; j++) {
                    let key = headerRow.getCell(j).value;
                    let value;
                    if (row.getCell(j).isHyperlink) {
                        value = row.getCell(j).value.text;
                    } else {
                        value = row.getCell(j).value;
                    }

                    if (headerRow.getCell(1).value !== 'code' || headerRow.getCell(2).value !== 'fullname' || headerRow.getCell(7).value !== 'province_id') {
                        throw new Error("File tải lên chưa đúng mẫu!");
                    }
                    if (value !== null && value !== '') {
                        if (typeof value === 'string') {
                            value = value.trim();
                        }
                        if (['gender_id'].includes(key)) {
                            let gender_id = vm.$utils.createAlias(value);
                            item.gender_id = gender_id == ('nam' || 0) ? 0 : gender_id == ('nu' || 1) ? 1 : 2;
                        } else if (['fullname', 'site_name', 'province_id'].includes(key)) {
                            item[key] = value + "";
                        } else if (['code'].includes(key)) {
                            item.code = value.toString().toUpperCase();
                        } else if (['types'].includes(key)) {
                            let result = [];
                            let key = this.getKeyOption(this.$params.eduPatient.providerTypeOptions);
                            if (value.toString().includes(",")) {
                                let array = value.split(',');
                                array.forEach((entry) => {
                                    if (key.includes(entry.trim())) {
                                        result.push(entry.trim());
                                    } else {
                                        result.push(-1);
                                    }
                                });
                            } else {
                                if(key.includes(value.toString())){
                                    result.push(value.toString());
                                }   else {
                                    result.push(-1);
                                }
                            }
                            item.types = result;
                        } else {
                            item[key] = value;
                        }
                    } else {
                        item[key] = null;
                    }
                }
                return item;
            }
        }
    }
</script>