<template>
    <div>
        <div class="row">
            <div class="col-12">
                <g-loading v-if="firstLoading" />
                <g-table v-else :filter="filterFormModel" :placeholder="'Nhập mã, họ tên...'" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                         :title="edu.group == 'phcn' || edu.group === 'care' || edu.group === 'other' ? 'Học viên' : 'Người cung cấp dịch vụ'" titleIcon="fa fa-users">
                    <template slot="filter">
                        <client-select v-if='isPrime()' attribute="client_id" :filter="{parent_id: $store.state.client.id}"  :model="filterFormModel" :errors="formErrors" :is-filter='true' class="mr-2" @change='search'/>
                        <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='load' />
                        <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="type"  :options="$params.eduPatient.providerTypeOptions" :isFilter="true" @change="search" />
                        <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="gender_id"  :options="$params.gender" :isFilter="true" @change="search" />
                    </template>
                    <template slot="action">
                        <export :filter="filterFormModel"  :edu="edu" v-if='edu.group == "phcn"' :totalCount="itemCount" :prime="isPrime()" :params="params" class="mr-1"/>
                        <g-button class="btn btn-gray btn-sm" icon="fa fa-file-excel" v-if='edu.group == "phcn" && isSub()' :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                        <import v-if='edu.group == "phcn" && isSub()' :params='params' :edu="edu" class="mr-1"/>

                        <export-care :filter="filterFormModel" v-if='edu.group == "care"' :edu="edu" :totalCount="itemCount" :prime="isPrime()" :params="params" class="mr-1"/>
                        <g-button class="btn btn-gray btn-sm" icon="fa fa-file-excel" v-if='edu.group == "care" && isSub()' :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                        <import-care v-if='edu.group == "care" && isSub()' :params='params' :edu="edu" class="mr-1"/>

                        <export-other :filter="filterFormModel" v-if='edu.group == "other"' :edu="edu" :totalCount="itemCount" :prime="isPrime()" :params="params" class="mr-1"/>
                        <g-button class="btn btn-gray btn-sm" icon="fa fa-file-excel" v-if='edu.group == "other" && isSub()' :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                        <import-other v-if='edu.group == "other" && isSub()' :params='params' :edu="edu" class="mr-1"/>
                        
                        <g-button v-if="permissions('patient_create') && isSub()"  :loading="saveLoading" size="sm" class="mr-1" variant="success" icon="fa fa-plus" content="Thêm mới" @click="create" />
                    </template>
                    <template #cell(fullname)="{item}">
                        <div ><span class="small text-gray-999">Mã: </span>{{item.code}}</div>
                        <div ><span class="small text-gray-999">Họ và tên: </span>{{item.fullname}}</div>
                    </template>
                    <template #cell(site_position)="{item}" v-if="edu.group == 'care'">
                        <span  v-if='item.site_position' >{{item.site_position}}</span>
                    </template>
                    <template #cell(other)="{item}">
                        <div ><span class="small text-gray-999">Năm sinh: </span>{{item.year}}</div>
                        <div><span class="small text-gray-999">Giới tính: </span>{{$params.gender.find(o=> o.value === item.gender_id).text}}</div>
                        <div v-if="params.tys[item.id]" ><span class="small text-gray-999">Loại hình người CCDV: </span> 
                            <span v-for="tys_id in params.tys[item.id]" :key="tys_id" >
                                <span v-if='$params.eduPatient.providerTypeOptions.find(o => o.value === tys_id)'>{{ $params.eduPatient.providerTypeOptions.find(o => o.value === tys_id).text}}</span>
                            </span>
                        </div>
                    </template>
                    <template #cell(province_id)="{item}">
                        <div v-if="item.province_id && params.provinces[item.province_id]">{{params.provinces[item.province_id].name}}</div>
                    </template>
                    <template #cell(action)="{item}">
                        <g-button :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                        <g-button v-if="isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil" @click="update(item.id)" />
                        <br/>
                        <log type="edu-student" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                        <g-button  v-if="isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    </template>
                </g-table>
            </div>
        </div>
        <b-modal v-model="showForm" size='lg' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-6'>
                    <edu-patient-select :label="edu.group === 'phcn' || edu.group === 'care' || edu.group === 'other' ? 'Học viên' : 'Người cung cấp dịch vụ'" :placeHolder="edu.group === 'phcn' || edu.group === 'care' || edu.group === 'other' ? 'Chọn học viên' : 'Chọn người cung cấp dịch vụ'"  :disabled="formModel.id" :required='false' :model="formModel" @change="changeEduPatient('edu_patient_id')" attribute="edu_patient_id" :errors="formErrors"></edu-patient-select>
                </div>
                 <div v-if="edu.group !=='care'" class='col-md'>
                    <form-select :close-on-select='false' :required='true' label="Loại hình người CCDV" :model="formModel" attribute="types" :errors="formErrors" :options="providerTypeOptions" />
                </div>
                <div class='col-md' v-if="edu.group ==='care'">
                    <form-select :required='true'  label="Loại hình người CCDV" :model="formModel" attribute="types" :errors="formErrors" :options="providerTypeOptions" />
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã"  :required='true' :model="formModel" :disabled="formModel.edu_patient_id" attribute="code" @change="changeEduPatient('code')" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên"  :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Năm sinh"  placeholder="YYYY"  :required='true' :model="formModel" attribute="year" type="number" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính"  :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Đơn vị công tác"  :model="formModel" attribute="site_name"  :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <province-select  label="Tỉnh/Thành phố" :required="true"  :model="formModel" attribute="province_id"  :errors="formErrors"></province-select>
                </div>
            </div>
            <div class='row' v-if="edu.group !== 'care' && edu.group !== 'other'">
                <div class='col-md-6'>
                    <form-input label="Chức danh chuyên môn" :model="formModel" attribute="site_position"  :errors="formErrors"></form-input>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button variant="secondary" class="float-right" @click="showForm = false">Đóng</b-button>
                <b-button variant="primary" class="float-right" @click="save()" :disabled="saveLoading">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='lg' :title="formTitle"   cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-6'>
                    <edu-patient-select label="Người cung cấp dịch vụ"  :disabled="formModel.id" :required='false' :model="formModel" attribute="edu_patient_id" :errors="formErrors"></edu-patient-select>
                </div>
                <div v-if="edu.group !=='care'" class='col-md'>
                    <form-select :close-on-select='false' :required='true' :disabled="true" label="Loại hình người CCDV" :model="formModel" attribute="types" :errors="formErrors" :options="providerTypeOptions" />
                </div>
                <div class='col-md' v-if="edu.group ==='care'">
                    <form-select :disabled="true" :required='true' label="Loại hình người CCDV" :model="formModel" attribute="types" :errors="formErrors" :options="providerTypeOptions" />
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã"  :disabled="true" :required='true' :model="formModel" attribute="code" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên" :required='true' :disabled="true" :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Năm sinh"  :required='true' :disabled="true" :model="formModel" attribute="year" type="number" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :required='true' :disabled="true" :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Đơn vị công tác"  :model="formModel" :disabled="true" attribute="site_name"  :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <province-select label="Tỉnh thành công tác" :required="true" :disabled="true"  :model="formModel" attribute="province_id"  :errors="formErrors"></province-select>
                </div>
            </div>
            <div class='row' v-if="edu.group !=='care' && edu.group !== 'other'">
                <div class='col-md-6'>
                    <form-input label="Chức danh chuyên môn"  :disabled="true"  :model="formModel" attribute="site_position"  :errors="formErrors"></form-input>
                </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
        <div class="form-group text-right">
            <b-button variant="secondary" class="ml-1" @click="$emit('close')">
                Đóng
            </b-button>
        </div>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import EduPatientSelect from '@/components/EduPatientSelect';
    import Log from "@/components/Log";
    import Export from './edu-phcn/EduStudentPhcnExport';
    import ExportCare from './edu-care/EduStudentCareExport';
    import Dropdown from '@/components/Dropdown';
    import Import from './edu-phcn/EduStudentPhcnImport.vue';
    import ImportCare from './edu-care/EduStudentCareImport.vue';
    import ImportOther from './edu-other/StudentImport';
    import ExportOther from './edu-other/StudentExport';
    import ImportMinxi from "@/mixins/Template";

    export default {
        props: ["edu"],
        components: {ProvinceSelect, EduPatientSelect, Log, Dropdown, Export, Import, ExportCare, ImportCare, ImportOther, ExportOther},
        mixins: [list, crud, role, ImportMinxi],
        data() {
            let headers = [];
            let fileName = null;
            let templateName = null;
            if (this.edu.group == "phcn") {
                headers = [
                    {header: 'code', key: 'code', label: 'Mã số người cung cấp dịch vụ*', width: 30, desc: "Nhập định dạng chữ (CCCD/CMT)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'year', key: 'year', label: 'Năm sinh*', width: 10, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 10, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'types', key: 'types', label: 'Loại hình người CCDV*', width: 30, desc: "Nhập 01 giá trị từ 1 đến 4 (Trong đó: " + this.$params.eduPatient.providerTypeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'site_position', key: 'site_position', label: 'Chức danh chuyên môn', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'site_name', key: 'site_name', label: 'Đơn vị công tác', width: 30, desc: "Nhập định dạng chữ"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ];
                fileName =  'dao_tao_phcn_hs';
                templateName = 'dao_tao_phcn_hs.xlsx';
            }
            if (this.edu.group == "care") {
                headers = [
                    {header: 'code', key: 'code', label: 'Mã số người cung cấp dịch vụ*', width: 30, desc: "Nhập định dạng chữ (CCCD/CMT)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'year', key: 'year', label: 'Năm sinh*', width: 10, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 10, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'types', key: 'types', label: 'Loại hình người CCDV*', width: 30, desc: "Nhập 01 giá trị từ 1 đến 4 (Trong đó: " + this.$params.eduPatient.providerTypeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'site_name', key: 'site_name', label: 'Đơn vị công tác', width: 30, desc: "Nhập định dạng chữ"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ];
                fileName  =  'dao_tao_cham_soc_hs';
                templateName = 'dao_tao_cham_soc_nguoi_ccdv.xlsx';
            }
            if(this.edu.group == "other") {
                headers = [
                    {header: 'code', key: 'code', label: 'Mã số người cung cấp dịch vụ*', width: 30, desc: "Nhập định dạng chữ (CCCD/CMT)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'year', key: 'year', label: 'Năm sinh*', width: 10, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 10, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'types', key: 'types', label: 'Loại hình người CCDV*', width: 30, desc: "Nhập 01 giá trị từ 1 đến 4 (Trong đó: " + this.$params.eduPatient.providerTypeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'site_name', key: 'site_name', label: 'Đơn vị công tác', width: 30, desc: "Nhập định dạng chữ"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ];
                fileName = 'dao_tao_chudekhac_hs';
                templateName = 'dao_tao_chudekhac_nguoi_ccdv.xlsx';
            }
            return {
                reloadBus: "reloadEduStudent",
                service: '/edu-student',
                defaultFilterFormModel: {
                    edu_id: this.edu.id,
                    keyword: '',
                    gender_id: null,
                    province_id: null,
                    type: null
                },
                defaultFormModel: {
                    edu_id: this.edu.id,
                    fullname: null,
                    types: null,
                    gender_id: null,
                    edu_patient_id: null,
                    year: null,
                    code: null,
                    province_id: null,
                },
                showFormView: null,
                providerTypeOptions: [],
                headers,
                fileName,
                templateName,
                indexRow: 4,
            }
        },
        methods: {
            changeEduPatient(attribute) {
                this.formModel.province_id = null;
                if(attribute == 'edu_patient_id'){
                    if (this.formModel.edu_patient_id) {
                        this.$service.get(`edu-patient/get?id=${this.formModel.edu_patient_id}`).then(response => {
                            this.formModel = Object.assign({}, this.defaultFormModel);
                            this.formModel.edu_patient_id = response.data.id;
                            this.formModel.code = response.data.code;
                            this.formModel.fullname = response.data.fullname;
                            this.formModel.gender_id = response.data.gender_id;
                            this.formModel.year = response.data.year;
                            this.formModel.types = response.data.types ? response.data.types.toString() : null;
                            this.formModel.province_id = response.data.province_id;
                            this.formModel.site_name = response.data.site_name;
                            this.formModel.site_position = response.data.site_position;
                        })
                    } else {
                        this.formModel = Object.assign({}, this.defaultFormModel);
                    }
                } else if (attribute == 'code'){
                    if (this.formModel.code) {
                        this.$service.get(`edu-patient/get-by-code?code=${this.formModel.code}&type=2`).then(response => {
                            if(response.data){
                                this.formModel = Object.assign({}, this.defaultFormModel);
                                this.formModel.edu_patient_id = response.data.id;
                                this.formModel.code = response.data.code;
                                this.formModel.fullname = response.data.fullname;
                                this.formModel.gender_id = response.data.gender_id;
                                this.formModel.year = response.data.year;
                                this.formModel.types = response.data.types ? response.data.types.toString() : null;
                                this.formModel.province_id = response.data.province_id;
                                this.formModel.site_name = response.data.site_name;
                                this.formModel.site_position = response.data.site_position;
                            }
                        })
                    } else {
                        this.formModel = Object.assign({}, this.defaultFormModel);
                    }
                }
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    if (this.beforeUpdate) {
                        this.beforeUpdate(response.data);
                    }
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin người CCDV: " + this.formModel.fullname;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            getEduPatientOption(){
                for (let i in this.$params.eduPatient.providerTypeOptions){
                    let id = this.$params.eduPatient.providerTypeOptions[i];
                    this.providerTypeOptions.push(id);
                }
                return this.providerTypeOptions;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.trim().toUpperCase();
                    }
                }, deep: true
            }
        },
        mounted(){
            this.getEduPatientOption();
        },
        computed: {
            fields() {
                let fields = [];
                if (this.edu.group == 'care') {
                    fields = [{key: 'fullname', label: "Tên học viên"},
                        {key: 'other', label: "Thông tin khác"},
                        {key: 'province_id', label: "Tình/thành công tác"},
                        {key: 'action', label: "Hành động", class: 'text-right'}, ];
                } else if (this.edu.group =='other') {
                    fields = [{key: 'fullname', label: "Tên học viên"},
                        {key: 'other', label: "Thông tin khác"},
                        {key: 'province_id', label: "Tình/thành công tác"},
                        {key: 'action', label: "Hành động", class: 'text-right'}, ];
                } else {
                    fields = [{key: 'fullname', label: "Tên học viên"},
                        {key: 'other', label: "Thông tin khác"},
                        {key: 'province_id', label: "Tình/thành công tác"},
                        {key: 'action', label: "Hành động", class: 'text-right'}, ];
                }
                return fields;
            },
        },
    }
</script>